<template>
    <div style="width: 100%">
        <el-card style="height: calc(100vh - 160px)">
            <div slot="header" class="header">客户公司管理</div>
            <div class="toolBar">
                <div style="display: flex">
                    <el-input
                        v-model="name"
                        style="width: 200px"
                        placeholder="请输入客户公司名称"
                    ></el-input>
                    <el-button
                        class="red"
                        @click="handleQueryTable('search')"
                        style="margin: 0 10px; height: 40px"
                        :loading="tableLoading"
                    >
                        查询
                    </el-button>
                </div>
                <el-button
                    class="red"
                    @click="handleAddDialog()"
                    style="height: 40px"
                >
                    新增
                </el-button>
            </div>
            <el-table
                v-loading="tableLoading"
                :data="tableData"
                height="calc(86vh - 250px)"
                style="width: calc(100% - 40px)"
            >
                <el-table-column
                    prop="clientName"
                    label="客户公司名称"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="contacts"
                    label="联系人"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="contactsPhone"
                    label="联系人手机"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注"
                    align="center"
                ></el-table-column>
                <el-table-column prop="status" label="状态" align="center">
                    <template slot-scope="scope">
                        <el-tag
                            v-if="scope.row.status === 0"
                            type="success"
                            size="small"
                        >
                            启用中
                        </el-tag>
                        <el-tag v-else type="danger" size="small">
                            已停用
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="action"
                    label="操作"
                    align="center"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-link
                            type="danger"
                            @click="handleDisabled(scope.row)"
                            v-if="scope.row.status === 0"
                        >
                            停用
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalNum"
                    v-if="totalNum > 0"
                ></el-pagination>
            </div>
        </el-card>
        <AddClientCompanyDialog
            ref="addClientCompanyDialogRef"
            @confirm="handleQueryTable('search')"
        />
    </div>
</template>
<script>
import { getClientCompanyList, disableClientCompany } from "@/api/client";
import AddClientCompanyDialog from "./components/AddClientCompanyDialog.vue";
export default {
    data() {
        return {
            name: "",
            tableData: [],
            tableLoading: false,
            pageNo: 1,
            pageSize: 20,
            totalNum: 0,
        };
    },
    components: {
        AddClientCompanyDialog,
    },
    computed: {},
    methods: {
        // 查询客户公司列表
        handleQueryTable(type) {
            if (type === "search") {
                this.pageNo = 1;
            }
            this.tableLoading = true;
            getClientCompanyList({
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                name: this.name,
            }).then((res) => {
                this.tableData = res.data.data.list;
                this.totalNum = res.data.data.total;
                this.tableLoading = false;
            });
        },

        handleClose() {
            this.dialogVisible = false;
        },

        handleDisabled(row) {
            const { id } = row;
            this.$AlertTipsDialog({
                msg: "确定停用该客户公司？",
                title: "停用确认",
                btnText: "确定",
                needCancel: true,
                callBack: () => {
                    disableClientCompany({
                        id,
                    }).then(() => {
                        this.$message.success("停用成功");
                        this.handleQueryTable();
                    });
                },
            });
        },

        handleCurrentChange(val) {
            this.pageNo = val;
            this.handleQueryTable();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.handleQueryTable();
        },

        handleAddDialog(){
            this.$refs.addClientCompanyDialogRef.handleAddDialog();
        },
    },
    mounted() {
        this.handleQueryTable();
    },
};
</script>
<style scoped lang="stylus">
/deep/ .el-card__header {
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
}
.detailHeader {
    line-height: 36px;
    font-weight: 600;
    font-size: 16px;
    padding-left: 20px;
    background: #f2f2f2;
    border-left: 4px solid var(--RED-C11C20);
}
.red {
    background-color: var(--RED-C11C20);
    color: white;
}
.header {
    color: white;
    font-size: 18px;
    font-weight: 700;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin: 10px 20px 100px 0px;
}

.ticket{
    min-width:800px;
}

.toolBar {
    display: flex;
    justify-content: space-between;
}
</style>
